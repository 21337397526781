import { mediaQueries, size, colors, H4 } from '@everlywell/leaves';
import styled from 'styled-components';

export const CompContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: ${size.lg}px;
  margin-bottom: 225px;
  & > * {
    border-right: solid 1px ${colors.gray2};
    &:nth-of-type(even) {
      border: none;
    }
  }
`;

export const Title = styled(H4)`
  text-align: center;
  margin-block-end: ${size.xl1}px;
  margin: ${size.lg}px;
`;

export const AddToCartWrapper = styled.div`
  padding: 0 ${size.md}px;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 0.8fr 0.8fr;
  grid-template-rows: 1fr auto auto;
  column-gap: ${size.lg}px;
  background-color: white;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 11;
  width: 100%;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
  border-radius: ${size.xs2}px ${size.xs2}px 0 0;
  ${mediaQueries.forTabletHorizontalUp} {
    padding: ${size.md}px ${size.lg}px;
  }

  ${mediaQueries.forPhoneOnly} {
    column-gap: ${size.sm}px;
  }
`;
