import { useHooks } from '@everlywell/leaves';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import React, { useContext, useEffect } from 'react';
import { Promo } from 'utils/helpers/activePromoHelpers';
import {
  ProductInfoContext,
  ComparisonModalContext,
} from 'utils/helpers/pdpContexts';
import { useMembershipProducts } from 'utils/hooks';
import { UserContext } from 'utils/hooks/useUserContext/context';

import ewAnalytics from '../../../utils/analytics';
import { ANALYTICS } from '../../../utils/constants/analytics';
import { ContentSection } from '../../../utils/types';
import ComparisonContent from './ComparisonContent';
import ProductCard from './ProductCard';
import * as S from './styles/indexStyles';

export interface ComparisonSectionProps {
  item: Item;
  content: ContentSection;
  promo: Promo;
}

type Item = {
  heading: string;
  subheading: string;
  image: IGatsbyImageData;
  number: number;
  slug: string;
  showRating: boolean;
};

export const ComparisonSection = ({
  content,
  promo,
}: ComparisonSectionProps): JSX.Element | null => {
  const {
    title,
    headline,
    subHeadline,
    listOfContentSections,
    desktopImages,
    description,
  } = content;
  const { handleComparisonModalClick, comparisonModalOpen } = useContext(
    ComparisonModalContext,
  );

  const [leftSlug, rightSlug] = description.description.trim().split('\n');

  const productInfo = useContext(ProductInfoContext);
  const { userData } = useContext(UserContext);

  // Retrieve product info for left and right products
  // and combine with current member data
  const { [leftSlug]: leftProduct } = useMembershipProducts(userData, {
    [leftSlug]: productInfo.productsBySlug[leftSlug],
  });

  const { [rightSlug]: rightProduct } = useMembershipProducts(userData, {
    [rightSlug]: productInfo.productsBySlug[rightSlug],
  });

  const handleClick = (e: any) => {
    handleComparisonModalClick(e);
    ewAnalytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: ANALYTICS.LABELS.PDP_UPGRADE_MODAL,
      },
    });
  };

  // NOTE: The Modal should be doing this by default, but it's not
  // I think this has to do with the fact that there are multiple
  // Modal components on the page, though I can't figure out
  // why that would matter.
  useEffect(() => {
    if (comparisonModalOpen) {
      document.documentElement.style.cssText =
        'height: 100vh; overflow: hidden';
    } else {
      document.documentElement.style.cssText = '';
    }
  }, [comparisonModalOpen]);

  const { isMobile } = useHooks.useWindowSize();

  if (!desktopImages) {
    return null;
  }

  return (
    <>
      <S.Container
        data-testid={`compare-${leftSlug}-with-${rightSlug}-section`}
      >
        {isMobile ? (
          <S.BgImg
            image={desktopImages[3].gatsbyImageData}
            alt="comparison background"
            style={{ position: 'absolute' }}
          />
        ) : (
          <S.BgImg
            image={desktopImages[2].gatsbyImageData}
            alt="comparison background"
            style={{ position: 'absolute' }}
          />
        )}
        <S.Title>{headline}</S.Title>
        <ProductCard
          description={subHeadline}
          image={desktopImages[1].gatsbyImageData}
          promo={promo}
          product={rightProduct}
        >
          <S.CompareButton
            appearance="secondary"
            onClick={handleComparisonModalClick}
            data-testid={`compare-${leftSlug}-with-${rightSlug}-cta`}
          >
            Compare Tests
          </S.CompareButton>
        </ProductCard>
      </S.Container>

      <S.Modal
        open={comparisonModalOpen}
        openHandler={handleClick}
        data-testid={`compare-${leftSlug}-with-${rightSlug}-modal`}
        persist
      >
        <ComparisonContent
          title={title}
          listOfContentSections={listOfContentSections}
          desktopImages={[desktopImages[0], desktopImages[1]]}
          setOpen={handleClick}
          promo={promo}
          leftProduct={leftProduct}
          rightProduct={rightProduct}
        />
      </S.Modal>
    </>
  );
};

export default ComparisonSection;
