import { size, colors } from '@everlywell/leaves';
import styled from 'styled-components';

export const Container = styled.div`
  &:nth-of-type(odd) {
    border-right: solid 1px ${colors.gray2};
    padding-inline-end: ${size.lg}px;
  }
`;
export const SectionHead = styled.div`
  display: flex;
  img {
    width: ${size.lg}px;
    margin-right: ${size.sm}px;
  }
`;
export const SectionTitle = styled.h3`
  font-size: 18px;
  line-height: ${size.lg}px;
  letter-spacing: 0.45px;
  margin: ${size.sm}px 0;
`;
