import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { typography, size } from '@everlywell/leaves';

import yupImg from './images/check.svg';
import nopeImg from './images/x-sign.svg';

export interface TextBoolean {
  bool: boolean;
  children: ReactNode;
}

const TextStyle = css`
  ${typography.bodyTextSmall}
  font-size: 14px;
  font-weight: 200;
  display: flex;
  align-items: center;
  margin: ${size.sm}px 0;
  img {
    width: 1em;
    height: 1em;
  }

  &::before {
    display: inline-block;
    height: 1em;
    width: 1em;
    content: '';
    margin-right: ${size.xs1}px;
  }
`;

const Checked = css`
  &::before {
    background-image: url(${yupImg});
  }
`;
const Exed = css`
  opacity: 0.5;
  &::before {
    background-image: url(${nopeImg});
  }
`;

export const BooleanText = styled.div<TextBoolean>`
  ${TextStyle}
  ${({ bool }) => {
    return bool ? Checked : Exed;
  }}
`;

export default BooleanText;
