import React, { FC } from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import * as S from './styles/markerSectionStyles';

interface MarkerSection {
  title: string;
  image: string;
  markers: string[];
}

const MarkerSection: FC<MarkerSection> = ({
  title,
  image,
  markers,
  children,
}) => {
  return (
    <S.Container>
      <S.SectionHead>
        <img src={image} alt={title} />
        <S.SectionTitle>{`${title} (${markers.length})`}</S.SectionTitle>
      </S.SectionHead>
      {children}
    </S.Container>
  );
};

export default MarkerSection;
