import { size, typography, colors, mediaQueries } from '@everlywell/leaves';
import { GatsbyImage as Img } from 'gatsby-plugin-image';
import styled from 'styled-components';

export const Container = styled.div`
  background-color: white;
  max-width: 375px;
  margin: auto;
  ${mediaQueries.forTabletHorizontalUp} {
    max-width: none;
    display: grid;
    grid-template-columns: 3fr 4fr;
    align-items: stretch;
  }
`;

export const ProductImage = styled(Img)`
  background-color: ${colors.teal1};
  justify-self: stretch;
  min-width: 200px;
  img {
    padding: 12px !important;
    object-fit: contain !important;
  }
`;

export const ProductTitle = styled.h4`
  ${typography.subtitle}
  font-size: ${size.lg}px;
  font-weight: normal;
  line-height: 1.28;
  color: ${colors.gray5};
  margin: ${size.xs1}px 0;
`;

export const ProductPrice = styled.p`
  ${typography.bodyText}
  margin: ${size.xs1}px 0;
`;

export const Description = styled.p`
  ${typography.bodyTextSmall}
  font-weight: normal;
  margin: ${size.xs1}px 0;
`;

export const Details = styled.div`
  padding: ${size.lg}px;
`;
