import { mediaQueries, typography, size } from '@everlywell/leaves';
import { GatsbyImage as Img } from 'gatsby-plugin-image';
import styled from 'styled-components';

export const Container = styled.a`
  text-decoration: none;
  width: 100%;
  ${mediaQueries.forTabletVerticalUp} {
    display: grid;
    ${mediaQueries.forTabletHorizontalUp} {
      grid-template-columns: 1fr 1fr;
    }
  }
`;

export const ProductImage = styled(Img)`
  width: 140px;
  ${mediaQueries.forTabletHorizontalUp} {
    grid-row: span 2;
  }
`;

export const ProductTitle = styled.div`
  ${typography.buttonTextSmall}
  font-weight: normal;
  margin: ${size.sm}px 0;
  ${mediaQueries.forTabletHorizontalUp} {
    margin-left: ${size.xs2}px;
    margin-right: ${size.xs2}px;
  }
`;

export const ProductPrice = styled.div`
  ${typography.bodyText}
  font-weight: 200;
`;
