import React, { ReactElement, FC } from 'react';

import { BooleanText } from './BooleanText';
import MarkerSection from './MarkerSection';
import { Comparison } from './ComparisonContent';

interface ComparisonSubSection {
  title: string;
  image: string;
  comp: Comparison;
}

const ComparisonSubSection: FC<ComparisonSubSection> = ({
  title,
  image,
  comp: { left, right, intersect },
}) => {
  return (
    <>
      <MarkerSection
        title={title}
        image={image}
        markers={[...intersect, ...left]}
      >
        {intersect.map(marker => (
          <BooleanText bool={true} key={`${title}-${marker}-left`}>
            {marker}
          </BooleanText>
        ))}
        {left.map(marker => (
          <BooleanText bool={true} key={`${title}-${marker}-left`}>
            {marker}
          </BooleanText>
        ))}
        {right.map(marker => (
          <BooleanText bool={false} key={`${title}-${marker}-left`}>
            {marker}
          </BooleanText>
        ))}
      </MarkerSection>
      <MarkerSection
        title={title}
        image={image}
        markers={[...intersect, ...right]}
      >
        {intersect.map(marker => (
          <BooleanText bool={true} key={`${title}-${marker}-right`}>
            {marker}
          </BooleanText>
        ))}
        {left.map(marker => (
          <BooleanText bool={false} key={`${title}-${marker}-right`}>
            {marker}
          </BooleanText>
        ))}
        {right.map(marker => (
          <BooleanText bool={true} key={`${title}-${marker}-right`}>
            {marker}
          </BooleanText>
        ))}
      </MarkerSection>
    </>
  );
};

export default ComparisonSubSection;
