import { IGatsbyImageData } from 'gatsby-plugin-image';
import React, { FC } from 'react';

import * as S from './styles/productDetailsStyles';

interface ProductDetails {
  image: IGatsbyImageData;
  title: string;
  slug: string;
  alt: string;
}

const ProductDetails: FC<ProductDetails> = ({
  image,
  title,
  slug,
}: ProductDetails) => {
  return (
    <S.Container href={`/products/${slug}`}>
      <S.ProductImage image={image} alt={title} />
      <S.ProductTitle>{title}</S.ProductTitle>
    </S.Container>
  );
};

export default ProductDetails;
