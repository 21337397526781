import Pricing from 'components/Pricing';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import React, { FC } from 'react';

import { Promo } from 'utils/helpers/activePromoHelpers';
import { ProductWithDiscountInfo } from 'utils/types';
import * as S from './styles/productCardStyles';

interface Props {
  description: string;
  image: IGatsbyImageData;
  promo?: Promo;
  product: ProductWithDiscountInfo;
}

const ProductCard: FC<Props> = ({
  description,
  image,
  children,
  promo,
  product,
}) => {
  const {
    productId,
    price,
    name: title,
    promotionable,
    memberPrice,
    memberHeroCopy,
  } = product;

  return (
    <S.Container>
      <S.ProductImage image={image} alt={title} />
      <S.Details>
        <S.ProductTitle>{title}</S.ProductTitle>
        <Pricing
          price={parseFloat(price)}
          promo={promo}
          productId={productId}
          promotionable={promotionable}
          memberPrice={memberPrice}
          subscribeSelected={false}
          memberHeroCopy={memberHeroCopy}
        />
        <S.Description>{description}</S.Description>
        {children}
      </S.Details>
    </S.Container>
  );
};

export default ProductCard;
