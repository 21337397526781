import {
  Button,
  H4,
  size,
  colors,
  mediaQueries,
  Modal as ModalRaw,
} from '@everlywell/leaves';
import { GatsbyImage as Img } from 'gatsby-plugin-image';
import styled from 'styled-components';

import SectionWrapper from '../../../SectionWrapper';

export const Container = styled(SectionWrapper)`
  background-size: cover;
  padding: ${size.lg}px ${size.xl1}px;
  overflow: hidden;
  ${mediaQueries.forTabletHorizontalUp} {
    padding: ${size.xl4}px ${size.xl6}px;
    display: grid;
    gap: ${size.xl4}px;
    grid-template-columns: 2fr 3fr;
    align-items: center;
    margin-bottom: ${size.xl4}px;
  }
`;

export const BgImg = styled(Img)`
  height: 100%;
  top: 0;
  right: 0;
  bottom: -600px; /* keeps image from traveling on top of copy */
  left: 0;
  z-index: -1;

  ${mediaQueries.forTabletHorizontalUp} {
    right: -400px; /* keeps image from traveling on top of copy */
    bottom: 0;
  }
`;

export const Title = styled(H4)`
  text-align: center;
  margin-block-end: ${size.xl2}px;
  ${mediaQueries.forTabletHorizontalUp} {
    font-size: ${size.xl2}px;
    line-height: 1.4;
    font-weight: 200;
    text-align: left;
  }
`;

export const CompareButton = styled(Button)`
  padding: ${size.xs1}px;
  color: ${colors.green5};
  font-weight: 600;
  border-radius: ${size.xs3}px;
  border-color: ${colors.green2};
  margin: ${size.sm}px 0;
`;

export const Modal = styled(ModalRaw)`
  /* So we go on top of the solvvy widget go big or go home. */
  z-index: 1900000001 !important;
`;
