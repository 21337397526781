import React, { FC } from 'react';
import { Promo } from 'utils/helpers/activePromoHelpers';

import * as S from './styles/addToCartStyles';

interface AddToCart {
  title: string;
  price: string;
  slug: string;
  isUpgrade: boolean;
  onClick: () => void;
  promo?: Promo;
  productId: number;
  promotionable?: boolean;
  memberPrice?: string;
  memberHeroCopy?: string;
}

const AddToCart: FC<AddToCart> = ({
  title,
  price,
  isUpgrade,
  slug,
  onClick,
  promo,
  productId,
  promotionable,
  memberPrice,
  memberHeroCopy,
}) => {
  const appearance = isUpgrade ? 'primary' : 'secondary';
  return (
    <>
      <S.ProductTitle to={`/products/${slug}`}>{title}</S.ProductTitle>
      <S.PriceWithPromo
        price={parseFloat(price)}
        promo={promo}
        productId={productId}
        promotionable={promotionable}
        memberPrice={memberPrice}
        subscribeSelected={false}
        memberHeroCopy={memberHeroCopy}
      />
      <S.CTA
        appearance={appearance}
        onClick={onClick}
        data-testid={`add-to-cart-${isUpgrade ? 'upgrade' : 'non-upgrade'}`}
      >
        Add to Cart
      </S.CTA>
    </>
  );
};

export default AddToCart;
