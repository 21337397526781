import { useCart } from 'components/Cart';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { zip, intersection, difference, upperFirst } from 'lodash-es';
import React, { useEffect } from 'react';
import { Promo } from 'utils/helpers/activePromoHelpers';

import ewAnalytics from '../../../utils/analytics';
import { ANALYTICS } from '../../../utils/constants/analytics';
import { ContentSection, ProductWithDiscountInfo } from '../../../utils/types';
import AddToCart from './AddToCart';
import ComparisonSubSection from './ComparisonSubSection';
import MarkerSection from './MarkerSection';
import ProductDetails from './ProductDetails';
import * as S from './styles/comparisonContentStyles';

interface ComparisonContentProps {
  title: string;
  listOfContentSections: ContentSection[];
  desktopImages: { gatsbyImageData: IGatsbyImageData }[];
  setOpen: (e: boolean) => void;
  leftProduct: ProductWithDiscountInfo;
  rightProduct: ProductWithDiscountInfo;
  promo?: Promo;
}

type Product = {
  title: string;
  price: string;
  variantId: number;
  slug: string;
  image: IGatsbyImageData;
};

const ComparisonContent = ({
  title,
  listOfContentSections,
  desktopImages,
  setOpen,
  promo,
  leftProduct,
  rightProduct,
}: ComparisonContentProps): JSX.Element => {
  const { addItemToCart } = useCart({
    addItemToCart: {
      onSuccess: () => {
        setOpen(false);
        return true;
      },
    },
  });

  // It is expected that the measured items in pdpLeft are a strict
  // subset of those measured by pdpRight
  const [pdpLeft, pdpRight] = listOfContentSections.map((section) =>
    section.listOfContentSections.map((s) => formatItems(s)),
  );

  const compare = zip(pdpLeft, pdpRight).map(([left, right]) =>
    joinMarkerData(left, right),
  );

  const PICombined = [leftProduct, rightProduct].map((product, idx) => ({
    title: product.name,
    price: product.price,
    image: desktopImages[idx],
    slug: product.slug,
    variantId: product.variantId,
    productId: product.productId,
    promotionable: product.promotionable,
    memberPrice: product.memberPrice,
    memberHeroCopy: product.memberHeroCopy,
  }));

  useEffect(() => {
    ewAnalytics.track({
      event: ANALYTICS.EVENTS.VIEWED_PAGE,
      data: {
        label: ANALYTICS.LABELS.PDP_UPGRADE_MODAL,
      },
    });
  }, []);

  const handleAddToCart =
    ({ variantId }: Omit<Product, 'image' | 'slug'>, isUpgrade: boolean) =>
    () => {
      ewAnalytics.track({
        event: ANALYTICS.EVENTS.ADD_TO_CART,
        data: {
          label: isUpgrade
            ? ANALYTICS.LABELS.ADD_TO_CART_UPGRADE_MODAL_WITH_UPGRADE
            : ANALYTICS.LABELS.ADD_TO_CART_UPGRADE_MODAL_NO_UPGRADE,
        },
      });

      addItemToCart({ variant_id: variantId, quantity: 1 });
    };

  return (
    <>
      <S.Title>{title}</S.Title>
      <S.CompContainer>
        {/* Product Details Sections */}
        <ProductDetails
          image={PICombined[0].image.gatsbyImageData}
          title={PICombined[0].title}
          slug={PICombined[0].slug}
          alt={PICombined[0].title}
        />
        <ProductDetails
          image={PICombined[1].image.gatsbyImageData}
          title={PICombined[1].title}
          slug={PICombined[1].slug}
          alt={PICombined[1].title}
        />
        {/* Main Comparison Sections */}
        {compare.map(({ title, image, comp }: ComparisonSubSection, idx) => (
          <ComparisonSubSection
            title={title}
            image={image}
            comp={comp}
            key={`comp-${idx}`}
          />
        ))}
      </S.CompContainer>

      {/* Bottom AddToCart Section */}
      <S.AddToCartWrapper>
        <AddToCart
          data-testid={`upgrade-add-to-cart-left`}
          title={PICombined[0].title}
          price={PICombined[0].price}
          slug={PICombined[0].slug}
          isUpgrade={false}
          onClick={handleAddToCart(
            {
              title: PICombined[0].title,
              price: PICombined[0].price,
              variantId: PICombined[0].variantId,
            },
            false,
          )}
          promo={promo}
          productId={PICombined[0].productId}
          promotionable={PICombined[0].promotionable}
          memberPrice={PICombined[0].memberPrice}
          memberHeroCopy={PICombined[0].memberHeroCopy}
        />
        <AddToCart
          data-testid={`upgrade-add-to-cart-right`}
          title={PICombined[1].title}
          price={PICombined[1].price}
          slug={PICombined[1].slug}
          isUpgrade={true}
          onClick={handleAddToCart(
            {
              title: PICombined[1].title,
              price: PICombined[1].price,
              variantId: PICombined[1].variantId,
            },
            true,
          )}
          promo={promo}
          productId={PICombined[1].productId}
          promotionable={PICombined[1].promotionable}
          memberPrice={PICombined[1].memberPrice}
          memberHeroCopy={PICombined[1].memberHeroCopy}
        />
      </S.AddToCartWrapper>
    </>
  );
};

export type Comparison = {
  intersect: string[];
  left: string[];
  right: string[];
};

export default ComparisonContent;

function compareMarkers(a: string[] = [], b: string[] = []): Comparison {
  const intersect = intersection(a, b);
  const left = difference(a, intersect);
  const right = difference(b, intersect);

  return { intersect, left, right };
}

function joinMarkerData(left?: MarkerSection, right?: MarkerSection) {
  return {
    title: left?.title || right?.title || '',
    image: left?.image || right?.image || '',
    comp: compareMarkers(left?.markers || [], right?.markers || []),
  };
}

function formatItems({
  title,
  image,
  description,
}: Omit<ContentSection, 'listOfContentSections'>) {
  if (!image?.file) {
    return;
  }

  const formattedItemNames = markersFromString(description.description);
  return {
    image: image.file.url || '',
    title: title,
    markers: formattedItemNames,
  };
}

function normalize(word: string): string {
  const words = word.trim().split(' ').map(upperFirst);
  return words.join(' ');
}

function markersFromString(markersString: string): string[] {
  return markersString.split('\n').map(normalize);
}
