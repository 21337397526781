import { mediaQueries, size, typography, Button } from '@everlywell/leaves';
import Pricing from 'components/Pricing';
import { Link } from 'gatsby';
import styled from 'styled-components';

export const ProductTitle = styled(Link)`
  ${typography.buttonTextSmall}
  text-decoration: none;
  font-weight: normal;
  margin-top: ${size.md}px;
  ${mediaQueries.forTabletHorizontalUp} {
    margin-top: ${size.xs2}px 0;
  }
`;

export const ProductPrice = styled.div`
  ${typography.bodyText}
  font-weight: 200;
  margin-top: 0;
`;

export const PriceWithPromo = styled(Pricing)`
  ${typography.bodyTextSmall}
  flex-direction: column;
  margin-top: 0;
  div {
    align-self: flex-start;
  }
  h3 {
    font-size: ${size.md}px !important;
    line-height: ${size.lg}px !important;
  }
  p {
    align-self: flex-start;
    font-size: 14px;
    font-weight: 600;
    line-height: ${size.md}px;
    margin-left: 0;
    ${mediaQueries.forTabletHorizontalUp} {
      line-height: ${size.lg}px;
    }
  }
  div p {
    font-size: 18px;
    font-weight: 400;
    line-height: ${size.lg}px;
    margin-left: ${size.xs1}px;
  }
`;

export const CTA = styled(Button)`
  align-self: center;
  margin: ${size.md}px 0;
  ${mediaQueries.forTabletHorizontalUp} {
    margin-right: ${size.xl1}px;
  }
`;
